import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { formatDate } from "./Hero";

const HeroTemplate = ({ category, viewMoreLink, heading }) => {
  const [news, setNews] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_NEWS_APP_BASE_URL}api/sections/?category=${category}`
      )
      .then((response) => {
        setNews(response.data);
        console.log(response.data, `${category} `);
      })
      .catch((error) => {
        console.error(`Error fetching ${category} news:`, error);
      });
  }, [category]);

  const toggleExpansion = (itemId) => {
    if (expandedItems.includes(itemId)) {
      setExpandedItems(expandedItems.filter((id) => id !== itemId));
    } else {
      setExpandedItems([...expandedItems, itemId]);
    }
  };

  // Check if there are no news items, then don't render the section
  if (news.length === 0) {
    return null;
  }

  return (
    <div className="mx-auto container p-2 sm:p-0 ">
      {news.length > 0 && (
        <>
          <h2 className="text-2xl text-left text-gray-400 py-2 px-6 gap-x-2  font-bold sm:text-3xl flex items-center">
            <span className="text-indigo-400 "> {heading}</span> News{" "}
            <Link
              to={viewMoreLink}
              className="ml-auto text-indigo-500 flex justify-between text-lg hover:underline"
            >
              View More
            </Link>
          </h2>
          <hr className="w-full mt-1" />
        </>
      )}

      <div className="flex flex-wrap">
        {news.map((item) => (
          <div key={item.id} className="lg:w-1/5 w-1/2 md:w-1/3 p-4">
            {item.images && item.images.length > 0 ? (
              <img
                alt="news"
                className="object-cover object-center w-full h-56 block"
                src={item.images}
                onError={(e) => {
                  e.target.src = "../../images/default-image.jpg";
                }}
              />
            ) : (
              <img
                alt="default"
                className="object-cover object-center w-full h-1/2 block"
                src="../../images/default-image.jpg"
              />
            )}
            <p className="text-sm italic text-gray-700 text-right font-semibold">
              {formatDate(item.datetime)}
            </p>
            <div className="mt-4">
              <h3 className="text-gray-500 text-lg tracking-wide title-font mb-1">
                {item.headlines.length <= 100 || expandedItems.includes(item.id)
                  ? item.headlines
                  : `${item.headlines.slice(0, 100)}...`}
              </h3>
              {item.headlines.length > 100 &&
                !expandedItems.includes(item.id) && (
                  <button
                    onClick={() => toggleExpansion(item.id)}
                    className="text-indigo-500 inline-flex items-center"
                  >
                    Read More
                  </button>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroTemplate;
