import moment from "moment";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { formatDate } from "../hero/Hero";

const SearchResultsPage = () => {
  const { search } = useLocation();
  const { query } = useParams();
  const searchQuery = new URLSearchParams(search).get("query");

  // Access the search results from the state
  const { searchResults } = useLocation().state;
  // state for readmore button
  const [expandedItems, setExpandedItems] = useState([]);

  // Function to toggle the expansion of an item
  const toggleExpansion = (itemId) => {
    if (expandedItems.includes(itemId)) {
      setExpandedItems(expandedItems.filter((id) => id !== itemId));
    } else {
      setExpandedItems([...expandedItems, itemId]);
    }
  };
  return (
    <div className="w-full min-h-screen flex flex-col">
      <div className="text-left text-gray-400 font-bold text-3xl w-full mb-2 py-5 container mx-auto ">
        Live News <span className="text-indigo-400">Highlights</span>
        <hr />
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-8 mx-auto">
          <div className="flex flex-wrap">
            {searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <div key={index} className="lg:w-1/5 w-1/2 md:w-1/3 p-4">
                  <img
                    alt="news"
                    className="object-cover object-center w-full h-56 block"
                    src={result.Images || "./images/default-image.jpg"}
                    onError={(e) => {
                      e.target.src = "../../images/default-image.jpg";
                    }}
                  />
                  <p className="text-xs italic text-gray-400 text-right">
                    {formatDate(result.Datetime)}
                  </p>
                  <div className="mt-4">
                    <h3 className="text-gray-500 text-lg tracking-wide title-font mb-1">
                      {result.Headlines.length <= 100 ||
                      expandedItems.includes(index)
                        ? result.Headlines // Display full headline if it's short or already expanded
                        : `${result.Headlines.slice(0, 100)}...`}{" "}
                      {/* Display up to 100 characters */}
                    </h3>
                    {result.Headlines.length > 100 &&
                      !expandedItems.includes(index) && (
                        <button
                          onClick={() => toggleExpansion(index)}
                          className="text-indigo-500 inline-flex items-center"
                        >
                          Read More
                        </button>
                      )}
                  </div>
                </div>
              ))
            ) : (
              <p className="w-full text-center text-gray-500 mt-8">
                No results found for this query.
              </p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchResultsPage;
