import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import useNewsFetcher from "./useNewsFetcher";

function Navbar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const [isNewDropdownOpen, setNewDropdownOpen] = useState(false);
  const toggleNewDropdown = () => setNewDropdownOpen(!isNewDropdownOpen);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const NewdropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        NewdropdownRef.current &&
        !NewdropdownRef.current.contains(event.target)
      ) {
        setNewDropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWS_APP_BASE_URL}api/live/${searchQuery}`
      );

      if (response.status === 200) {
        const results = await response.json();
        navigate(
          `/searchResultsPage?query=${encodeURIComponent(searchQuery)}`,
          {
            state: { searchResults: results },
          }
        );
      } else if (response.status === 400) {
        const errorData = await response.json();
        setError(errorData.error || "Bad Request");
      } else {
        setError("An unexpected error occurred");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError(error.message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const closeErrorPopup = () => setError(null);

  return (
    <div>
      <nav className=" border-gray-200 bg-gray-900">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to={"/"} className="flex items-center">
            <img
              src="../../images/newsapp_logo.png"
              className="h-12 mr-2"
              alt="logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
              Up<span className="text-indigo-500"> 2 </span>Date
            </span>
          </Link>
          <div className="flex md:order-2">
            <button
              type="button"
              className="md:hidden text-gray-500 text-gray-400 hover:bg-gray-100  focus:outline-none focus:ring-4 focus:ring-gray-200 focus:ring-gray-700 rounded-lg text-sm p-2.5 mr-1"
              onClick={toggleMenu}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
              <span className="sr-only">Open main menu</span>
            </button>
          </div>
          <div
            className={`${
              isMenuOpen ? "block" : "hidden"
            } w-full md:flex md:w-auto md:order-1`}
          >
            <div className="relative mt-3 md:hidden">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="search-navbar"
                className="block w-full p-2 pl-10 text-sm 0 border  rounded-lg    bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0  bg-transparent ">
              <li>
                <Link
                  to="/news/Business News"
                  className={`${
                    location.pathname === "/news/Business%20News"
                      ? "text-blue-500"
                      : "text-white"
                  }`}
                >
                  Business
                </Link>
              </li>
              <li>
                <Link
                  to="/news/Sports News"
                  className={`${
                    location.pathname === "/news/Sports%20News"
                      ? "text-blue-500"
                      : "text-white"
                  }`}
                >
                  Sports
                </Link>
              </li>
              <li>
                <Link
                  to="/news/International News"
                  className={`${
                    location.pathname === "/news/International%20News"
                      ? "text-blue-500"
                      : "text-white"
                  }`}
                >
                  International
                </Link>
              </li>
              <li>
                <Link
                  to="/news/All News"
                  className={`${
                    location.pathname === "/news/All%20News"
                      ? "text-blue-500"
                      : "text-white"
                  }`}
                >
                  All News
                </Link>
              </li>
            </ul>
          </div>
          <div className="hidden md:flex md:-order-first justify-end items-end">
            <input
              type="text"
              required
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-56 p-1 pl-3 text-sm text-gray-900 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search Live news here..."
            />
          </div>
        </div>
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center ${
            error ? "block" : "hidden"
          }`}
        >
          <div className="bg-white border border-gray-300 rounded-lg shadow-md p-6 max-w-sm mx-auto">
            <h3 className="text-lg font-medium text-gray-900">Error</h3>
            <p className="mt-2 text-sm text-gray-600">{error}</p>
            <button
              onClick={closeErrorPopup}
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
