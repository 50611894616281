import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import SkeltonLoader from "./SkeltonLoader";
import { useParams, useLocation } from "react-router-dom";
import { formatDate } from "../hero/Hero";

const NewsTemplate = ({ setHasNews }) => {
  const { category: initialCategory } = useParams();
  const [category, setCategory] = useState(initialCategory);
  const [news, setNews] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);

  const observerRef = useRef();
  const location = useLocation();

  const toggleExpansion = (itemId) => {
    setExpandedItems((prevItems) =>
      prevItems.includes(itemId)
        ? prevItems.filter((id) => id !== itemId)
        : [...prevItems, itemId]
    );
  };

  const fetchData = async (page, selectedCategory = category) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_NEWS_APP_BASE_URL}api/sections/allnews/?category=${selectedCategory}&page=${page}`
      );
      setNews((prevNews) => [...prevNews, ...response.data.values]);
      setNextPage(response.data.next);
      setLoading(false);
      setHasNews?.(response.data.values.length > 0);
    } catch (error) {
      console.error(`Error fetching ${selectedCategory} news:`, error);
      setLoading(false);
      setHasNews?.(false);
    }
  };

  useEffect(() => {
    // Update the category when the location changes
    const newCategory = location.pathname
      .split("/news/")[1]
      .replace(/%20/g, " ");
    setCategory(newCategory);
  }, [location]);
  useEffect(() => {
    setNews([]);
    setCurrentPage(1);
    fetchData(1);
  }, [category]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && nextPage) {
          setCurrentPage((prevPage) => prevPage + 1); // Increment the page
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [nextPage]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchData(currentPage);
    }
  }, [currentPage]);

  const handleCategoryChange = (e) => {
    setLoading(true);
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setCurrentPage(1);
    setNews([]);
    setLoading(false);
  };

  return (
    <div className="w-full min-h-screen flex flex-col">
      <div className="text-left text-gray-400 font-bold text-3xl w-full mb-2 py-5 px-10 flex items-center justify-between container mx-auto border-b">
        <div>
          {category.charAt(0).toUpperCase() + category.slice(1)}
          <span className="text-indigo-400"> Highlights</span>
        </div>
        {window.location.pathname === "/news/All%20News" && (
          <select
            value={category}
            onChange={handleCategoryChange}
            className="border rounded p-2 text-lg"
          >
            <option value="All News">Select News</option>
            <option value="World News">World News</option>
            <option value="Local News">Local News</option>
            <option value="Fashion News">Fashion News</option>
            <option value="Science News">Science News</option>
            {/* <option value="Others News">Other News</option> */}
          </select>
        )}
      </div>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-8 mx-auto">
          {loading && currentPage === 1 ? (
            <SkeltonLoader />
          ) : (
            <div className="flex flex-wrap justify-center">
              {news.length === 0 ? (
                <div className="text-center text-gray-500 w-full py-8">
                  No News to show.
                </div>
              ) : (
                news.map((result, index) => (
                  <div key={index} className="lg:w-1/5 w-1/2 md:w-1/3 p-4 ">
                    <img
                      alt="news"
                      className="object-cover object-center w-full h-56 block "
                      src={result.images || "../../images/default-image.jpg"}
                      onError={(e) => {
                        e.target.src = "../../images/default-image.jpg";
                      }}
                    />
                    <p className="text-sm italic text-gray-600 text-right font-semibold">
                      {formatDate(result.datetime)}
                    </p>
                    <div className="mt-4 ">
                      <h3 className="text-gray-500 text-lg tracking-tight title-font">
                        {result.headlines.length <= 100 ||
                        expandedItems.includes(index)
                          ? result.headlines
                          : `${result.headlines.slice(0, 100)}...`}
                      </h3>
                      {result.headlines.length > 100 &&
                        !expandedItems.includes(index) && (
                          <button
                            onClick={() => toggleExpansion(index)}
                            className="text-indigo-500 inline-flex items-center"
                          >
                            Read More
                          </button>
                        )}
                    </div>
                  </div>
                ))
              )}
              <div ref={observerRef} style={{ height: "1px" }} />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default NewsTemplate;
