import { Route, Routes } from "react-router-dom";
import "./App.css";
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import NewsPage from "./components/NewsPage";
import SearchResultsPage from "./components/routes/SearchResultsPage";
import NewsTemplate from "./components/routes/NewsTemplate";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/news/:category" element={<NewsTemplate />} />
        <Route path="/searchResultsPage" element={<SearchResultsPage />} />
        <Route path="/newspage" element={<NewsPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
