import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";

import LatestNews from "../carousals/LatestNews";
import HeroTemplate from "./HeroTemplate";
import { responsive } from "../carousals/data";
import { Link } from "react-router-dom";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const formatDate = (datetime) => {
  const now = moment();
  const newsTime = moment(datetime);
  const diffInMinutes = now.diff(newsTime, "minutes");
  const diffInHours = now.diff(newsTime, "hours");
  const diffInDays = now.diff(newsTime, "days");
  const diffInMonths = now.diff(newsTime, "months");

  if (diffInMinutes < 1) {
    return "A moment ago";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else {
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  }
};
function Hero() {
  const [headlines, setHeadlines] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_NEWS_APP_BASE_URL}api/headlines`)
      .then((response) => {
        console.log(response);
        setHeadlines(response.data);
        setLoading(false);
        console.log("headlines newses", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col">
      <div className=" flex-grow">
        <section className=" flex flex-col lg:h-full overflow-auto text-gray-600 body-font bg-white">
          <div className="container px-4 py-8 mx-auto flex flex-wrap">
            <div className="text-left text-gray-400 font-bold text-3xl w-full mb-6 ">
              News <span className="text-indigo-400">Highlights</span>
              <hr />
            </div>
            <div className="flex lg:flex-row flex-col flex-wrap ">
              <div className="flex flex-wrap lg:w-1/2 relative border ">
                {loading ? (
                  <div className="w-full ">
                    <div class="w-full h-full border-2 rounded-md mx-auto ">
                      <div class="flex animate-pulse flex-col  h-full justify-center space-x-5">
                        <div class="w-full bg-gray-300 h-full  "></div>
                        <div class=" flex justify-between flex-row space-x-3 p-2">
                          <div class="w-full bg-gray-300 h-6 rounded-md "></div>
                          <div class=" w-56 bg-gray-300 h-6 rounded-md "></div>
                        </div>

                        <div class="flex flex-row space-x-3 p-2">
                          <div class=" w-96 bg-gray-300 h-6 rounded-md "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : headlines.length > 0 ? (
                  <div className="md:p-2 p-1 w-full h-4/4 relative bg-gray-200">
                    {headlines[0].images && headlines[0].images.length > 0 ? (
                      <img
                        alt="gallery"
                        className="w-full h-full object-cover object-center block"
                        src={headlines[0].images}
                      />
                    ) : (
                      <img
                        alt="default"
                        className="w-full h-full object-cover object-center block"
                        src="./images/default-image.jpg"
                      />
                    )}

                    <div className="absolute bottom-0 left-0 w-full p-4 bg-gray-200 bg-opacity-100">
                      <p className="text-gray-600 text-end text-sm italic font-semibold">
                        {formatDate(headlines[0].datetime)}
                      </p>
                      <h1 className="text-black text-3xl">
                        {headlines[0].headlines}
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="w-full  justify-center items-center text-gray-500 text-center">
                    No headlines to show.
                  </div>
                )}
              </div>

              <div className="flex flex-wrap lg:w-1/2 bg-gray-100">
                {loading
                  ? Array.from({ length: 6 }).map((_, index) => (
                      <div className="w-60 h-24 border-2 rounded-md mx-auto mt-10 mb-10">
                        <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
                          <div className="w-12 bg-gray-300 h-12 rounded-full"></div>
                          <div className="flex flex-col space-y-3">
                            <div className="w-36 bg-gray-300 h-6 rounded-md"></div>
                            <div className="w-24 bg-gray-300 h-6 rounded-md"></div>
                          </div>
                        </div>
                      </div>
                    ))
                  : headlines.length > 1 &&
                    headlines.slice(1).map((news, index) => (
                      <div key={index} className="md:p-2 p-1 w-1/2">
                        <div>
                          {news.images && news.images.length > 0 ? (
                            <img
                              alt="gallery"
                              className="w-full object-cover h-full object-center block"
                              src={news.images}
                              onError={(e) => {
                                e.target.src = "../../images/default-image.jpg";
                              }}
                            />
                          ) : (
                            <img
                              alt="default"
                              className="w-full object-cover h-full object-center block"
                              src="./images/default-image.jpg"
                              onError={(e) => {
                                e.target.src = "../../images/default-image.jpg";
                              }}
                            />
                          )}
                          <p className="text-gray-800 text-end text-sm italic font-semibold">
                            {formatDate(news.datetime)}
                          </p>
                          <h1 className="text-lg">{news.headlines}</h1>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </section>
        {/* Business News part */}
        <HeroTemplate
          category="business news"
          viewMoreLink="/news/Business News"
          heading={"Business "}
        />
        {/* Sports News part */}
        <HeroTemplate
          category="sports news"
          viewMoreLink="/news/Sports News"
          heading={"Sports"}
        />
        {/* Other News part */}
        <HeroTemplate
          category="World News"
          viewMoreLink="/news/World News"
          heading={"World News"}
        />
      </div>
    </div>
  );
}

export default Hero;
