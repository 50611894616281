import React from "react";

const SkeltonLoader = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row space-x-10">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="w-full h-full border-2 rounded-md mx-auto "
          >
            <div className="flex animate-pulse flex-col  h-full justify-center  space-x-5">
              <div className=" w-full bg-gray-300 h-40  "></div>
              <div className=" flex  flex-row space-x-3 p-2">
                <div className="w-full bg-gray-300 h-6 rounded-md "></div>
                <div className=" w-56 bg-gray-300 h-6 rounded-md "></div>
              </div>
              <div className="flex flex-row space-x-3 p-2">
                <div className=" w-56 bg-gray-300 h-6 rounded-md "></div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-row space-x-10 mt-10">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="w-full h-full border-2 rounded-md mx-auto "
          >
            <div className="flex animate-pulse flex-col  h-full justify-center  space-x-5">
              <div className=" w-full bg-gray-300 h-40  "></div>
              <div className=" flex  flex-row space-x-3 p-2">
                <div className="w-full bg-gray-300 h-6 rounded-md "></div>
                <div className=" w-56 bg-gray-300 h-6 rounded-md "></div>
              </div>
              <div className="flex flex-row space-x-3 p-2">
                <div className=" w-56 bg-gray-300 h-6 rounded-md "></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeltonLoader;
