import React, { useState } from "react";
import "./Newspage.css";

function NewsPage() {
  const paragraph = `The Justice Department has asked a federal judge overseeing the criminal case against former President Donald Trump in Washington to step in after Trump released a post online that appeared to promise revenge on anyone who goes after him.

    Prosecutors on Friday requested that U.S. District Court Judge Tanya Chutkan issue a protective order concerning evidence in the case, a day after Trump pleaded not guilty to charges of trying to overturn his 2020 election loss and block the peaceful transition of power. The order, different from a “gag order,” would limit what information Trump and his legal team could share publicly about the case brought by special counsel Jack Smith.
    
    Chutkan on Saturday gave Trump's legal team until 5 p.m. Monday to respond to the government's request. Trump's legal team, which has indicated he would look to slow the case down despite prosecutors' pledge of a speedy trial, then filed a request to extend the response deadline to Thursday and to hold a hearing on the matter, saying it needed more time for discussion.
    
    Chutkan swiftly denied that extension request Saturday evening, reaffirming that Trump must abide by Monday's deadline.
    
    Protective orders are common in criminal cases, but prosecutors said it's “particularly important in this case” because Trump has posted on social media about "witnesses, judges, attorneys and others associated with legal matters pending against him."
    
    Prosecutors pointed specifically to a post on Trump's Truth Social platform from earlier Friday in which Trump wrote, in all capital letters, “If you go after me, I’m coming after you!”
    
    Prosecutors said they are ready to hand over a "substantial" amount of evidence — “much of which includes sensitive and confidential information” — to Trump's legal team.
    
    They told the judge that if Trump were to begin posting about grand jury transcripts or other evidence provided by the Justice Department, it could have a “harmful chilling effect on witnesses or adversely affect the fair administration of justice in this case.”
    
    Prosecutors' proposed protective order seeks to prevent Trump and his lawyers from disclosing materials provided by the government to anyone other than people on his legal team, possible witnesses, the witnesses' lawyers or others approved by the court. It would put stricter limits on “sensitive materials,” which would include grand jury witness testimony and materials obtained through sealed search warrants.
    
    A Trump spokesperson said in an emailed statement that the former president's post “is the definition of political speech” and was made in response to “dishonest special interest groups and Super PACs.”
    
    Chutkan, a former assistant public defender who was nominated to the bench by President Barack Obama, has been one of the toughest punishers of rioters who stormed the Capitol in the Jan. 6, 2021, attack fueled by Trump’s baseless claims of a stolen election.
    
    The indictment unsealed this week accuses Trump of brazenly conspiring with allies to spread falsehoods and concoct schemes intended to overturn his election loss to President Joe Biden as his legal challenges foundered in court.
    
    The indictment chronicles how Trump and his Republican allies, in what Smith described as an attack on a “bedrock function of the U.S. government,” repeatedly lied about the results in the two months after he lost the election and pressured his vice president, Mike Pence, and state election officials to take action to help him cling to power.
    
    Trump faces charges that include conspiracy to defraud the U.S. and conspiracy to obstruct Congress’ certification of Biden’s electoral victory.
    
    It's the third criminal case brought this year against the early front-runner in the 2024 Republican presidential primary. But it’s the first case to try to hold Trump responsible for his efforts to remain in power during the chaotic weeks between his election loss and the attack by his supporters on the U.S. Capitol on Jan. 6, 2021.
    
    Smith has also charged Trump in Florida federal court with illegally hoarding classified documents at Trump's Mar-a-Lago estate and thwarting government efforts to get them back.
    
    The magistrate judge in that case agreed to a protective order in June that prohibits Trump and his legal team from publicly disclosing evidence turned over to them by prosecutors without prior approval. Prosecutors are seeking another protective order in that case with more rules about the defense team's handling of classified evidence.
    
    After his court appearance on Thursday in the Washington case, Trump characterized the prosecution as a “persecution” designed to hurt his 2024 presidential campaign. His legal team has described it as an attack on his right to free speech and his right to challenge an election that he believed had been stolen.
    
    Smith has said prosecutors will seek a “speedy trial" against Trump in the election case. Judge Chutkan has ordered the government to file a brief by Thursday proposing a trial date. The first court hearing in front of Chutkan is scheduled for Aug. 28.
     Trump is already scheduled to stand trial in March in the New York case stemming from hush-money payments made during the 2016 campaign and in May in the classified documents case.`;

  const maxCharactersToShow = 800; // Maximum number of characters to show initially
  const [isExpanded, setIsExpanded] = useState(false);
  const truncatedContent = paragraph.slice(0, maxCharactersToShow);
  const isTruncated = paragraph.length > maxCharactersToShow;

  const handleReadMoreClick = () => {
    setIsExpanded(true);
  };
  return (
    <div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-10 items-center justify-center flex-col">
          <img
            className="lg:w-3/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="hero"
            src="https://gdb.voanews.com/01000000-0aff-0242-4628-08db96727ebb_w1023_r1_s.jpg"
          />
          <div className="text-center lg:w-3/4 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Trump Wants New Judge to Hear Election Fraud Case{" "}
            </h1>
            <p
              className="title-font sm:text-2xl mb-4  text-gray-900 text-2xl text-justify"
              style={{ lineHeight: "1.8" }}
            >
              {isExpanded ? paragraph : truncatedContent}
              {isTruncated && !isExpanded && (
                <button
                  className="text-indigo-600"
                  onClick={handleReadMoreClick}
                >
                  Read more
                </button>
              )}
            </p>
            {/* Microphone icon */}
            <div className="absolute bottom-0 right-0 p-4 sticky-icon">
              <button
                className="bg-indigo-500 rounded-full p-3 hover:bg-indigo-600 "
                title="Read Aloud"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  id="microphone"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-8 h-8 text-white"
                >
                  <path d="M15 18.405a1 1 0 0 0-1 1l.001 5.16c.076 6.14 4.928 11.185 11.007 11.687-.001.014-.008.026-.008.04v2.71h-4.546a1 1 0 1 0 0 2h11.092a1 1 0 1 0 0-2H27v-2.71c0-.014-.007-.026-.008-.04 6.055-.501 10.905-5.524 11.006-11.636L38 19.405a1 1 0 1 0-2 0l-.002 5.178c-.088 5.359-4.573 9.719-9.998 9.719-5.447 0-9.933-4.379-9.999-9.759L16 19.405a1 1 0 0 0-1-1z"></path>
                  <path d="M32.9 24.482V15.84c0-3.771-3.096-6.839-6.9-6.839s-6.9 3.068-6.9 6.839v8.642c0 3.771 3.096 6.839 6.9 6.839s6.9-3.068 6.9-6.839zm-11.8 0V15.84c0-2.668 2.198-4.839 4.9-4.839s4.9 2.171 4.9 4.839v8.642c0 2.668-2.198 4.839-4.9 4.839s-4.9-2.171-4.9-4.839z"></path>
                </svg>{" "}
                {/* Badge with responsibility text */}
                <div className="absolute -top-8 right-1 bg-indigo-600 text-white rounded-full -p-2 text-s font-bold animate-bounce">
                  Read Aloud!!!
                </div>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewsPage;
